import axios from "./axios";
// post-model
export function postApi(params) {
  return axios({
    url: "/xxx/post",
    data: params,
    method: "post",
  });
}

// get-model
export function getApi(params) {
  return axios({
    url: "/xxx/get",
    params,
  });
}
// 获取商品类型
export function getgoods_categoryApi(params) {
  return axios({
    url: "/config/goods_category",
    params,
  });
}
// 获取商品数据
export function getgoods_listApi(params) {
  return axios({
    url: "/config/goods_list",
    params,
  });
}
// 获取商品详情数据
export function getgoods_detailApi(params) {
  return axios({
    url: "/config/goods_detail",
    params,
  });
}
// 获取新闻类型数据
export function getnews_categorylApi(params) {
  return axios({
    url: "/config/news_category",
    params,
  });
}
// 获取新闻列表数据
export function getnews_listlApi(params) {
  return axios({
    url: "/config/news_list",
    params,
  });
}
// 获取新闻详情数据
export function getnews_detailApi(params) {
  return axios({
    url: "/config/news_detail",
    params,
  });
}

// 联系我们
export function contactUsApi(params) {
  return axios({
    url: "/config/submit",
    data: params,
    method: "post",
  });
}

// 获取案例列表
export function getCaseListApi(params) {
  return axios({
    url: "/config/case_list",
    params,
  });
}
