import { Message } from "element-ui";
export default function Rem(designWidth, maxWidth, minWidth) {
  var doc = document,
    win = window,
    docEl = doc.documentElement || document.body,
    remStyle = document.createElement("style"),
    tid,
    evt = "onorientationchange" in win ? "orientationchange" : "resize";

  function refreshRem() {
    var width = docEl.getBoundingClientRect().width;
    maxWidth = maxWidth || 1400;
    width < minWidth && (width = minWidth);
    var rem = (width * 100) / designWidth;
    console.log("htmlsize:", rem, width, designWidth, maxWidth, minWidth);
    remStyle.innerHTML = "html{font-size:" + rem + "px;}";
  }

  if (docEl.firstElementChild) {
    docEl.firstElementChild.appendChild(remStyle);
  } else {
    var wrap = doc.createElement("div");
    wrap.appendChild(remStyle);
    doc.write(wrap.innerHTML);
    wrap = null;
  }
  refreshRem();
  win.addEventListener(
    evt,
    function () {
      clearTimeout(tid); //防止执行两次
      tid = setTimeout(refreshRem, 300);
    },
    false
  );

  win.addEventListener(
    "pageshow",
    function (e) {
      if (e.persisted) {
        // 浏览器后退的时候重新计算
        clearTimeout(tid);
        tid = setTimeout(refreshRem, 300);
      }
    },
    false
  );

  if (doc.readyState === "complete") {
    doc.body.style.fontSize = "0.16rem";
  } else {
    doc.addEventListener(
      "DOMContentLoaded",
      function (e) {
        doc.body.style.fontSize = "0.16rem";
      },
      false
    );
  }
}
