<template>
  <div class="sidebarbox">
    <div class="title1" @mouseenter="() => {
      this.title1 = true;
      dhFun();
    }
      " @mouseleave="() => {this.title1 = false;close()}">
      <p>CONTACT US</p>

    </div>
    <!-- <a href="#" target="_blank" class="title2 flex" @mouseenter="() => {
      this.title2 = true;
      dhFun();
    }
      " @mouseleave="() => (this.title2 = false)">
      <img src="../../assets/images/all/pc-sideber-title1.png" alt="" style="width: 0.24rem; height: 0.24rem" />
    </a> -->

    <a href="#"  title="Email" class="title3 flex" @mouseenter="() => {
      this.title3 = true;
      dhFun();
    }
      " @mouseleave="() => (this.title3 = false)">
      <img src="../../assets/images/all/pc-sideber-title2.png" alt="" style="width: 0.24rem; height: 0.24rem" />
    </a>
    <a href="#"  class="title4 flex" :class="this.title4 == true ? 't5' : 'title4 flex'" @mouseenter="() => {
        this.title4 = true;
        dhFun();
      }
        " @mouseleave="() => (this.title4 = false)">
      <img src="../../assets/images/all/pc-sideber-title3.png" alt="" style="width: 0.24rem; height: 0.24rem" />
    </a>
    <!-- 弹出区 -->
    <div class="title1_main SideGSAPwidth" v-show="title1" @mouseenter="() => (this.title1 = true)" @mouseleave="() => {this.title1 = false;close()}">
      <div class="top">
        <h2>Contact Form</h2>
        <img class="point" src="../../assets/images/all/pc-close.png" alt="" @click="() =>{this.title1 = false;close()}" />
      </div>
      <div class="putbox">
        <el-input class="put" v-model="formData.name" placeholder="Name"></el-input>
        <el-input class="put" v-model="formData.email" placeholder="E-mail"></el-input>
      </div>
      <div class="msgbox">
        <el-input class="msgput" type="textarea" placeholder="Message" v-model="formData.content">
        </el-input>
      </div>
      <div class="subbtn" @click="dosub">Submit</div>
    </div>
    <!-- 
    <a href="#" target="_blank" class="title_main t1 SideGSAPwidth" v-show="title2" @mouseenter="() => (this.title2 = true)" @mouseleave="() => (this.title2 = false)">Skype
    </a> -->
    <a href="#" title="Email" class="title_main t1 SideGSAPwidth" v-show="title3"
      @mouseenter="() => (this.title3 = true)" @mouseleave="() => (this.title3 = false)">
      E-mail
    </a>
    <a href="#"  class="title_main t2 SideGSAPwidth" v-show="title4" @mouseenter="() => (this.title4 = true)" @mouseleave="() => (this.title4 = false)">Whatsapp</a>
  </div>

</template>

<script>
import { contactUsApi } from "@/network/api";
import { gsap } from "gsap";

export default {
  data() {
    return {
      title1: false,
      title2: false,
      title3: false,
      title4: false,
      formData: {
        name: '',
        email: '',
        content: ''
      },

      mbitemshow: false,//手机端弹出
      mb_title1: false//意见反馈
    };
  },

  methods: {
    titleclick() {
      this.mb_title1 = !this.mb_title1;
    },
    mbshowclick() {
      if (this.mbitemshow == false) {
        this.mbitemshow = true;
      } else {
        this.mbitemshow = false;
        this.mb_title1 = false;
      }

    },
    dhFun() {
      gsap.fromTo(
        ".SideGSAPwidth",
        {
          x: -200,
        },
        {
          x: 0,
          duration: 0.3,
        }
      );
    },
    close() {
      this.formData = this.$options.data().formData;
    },
    async dosub() {
      const res = await contactUsApi(this.formData);
      if (res.code == 1) {
        this.$message.success('success');
        this.formData = this.$options.data().formData;
      }
      this.formData = this.$options.data().formData;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebarbox {
  width: 0.58rem;
  height: 3.45rem;
  position: fixed;
  top: 30%;
  left: 0;
  cursor: pointer;
  z-index: 1999;

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title1 {
    width: 100%;
    height: 1.71rem;
    background: #222c43;
    border-radius: 0 0.2rem 0 0;
    color: #ffff;

    p {
      font-size: 0.2rem;
      white-space: nowrap;
      transform: rotate(270deg);
      line-height: 2.5rem;
      position: relative;
    }

    .title1_icon {
      width: 0.16rem;
      height: 0.16rem;
      position: absolute;
      top: 1.42rem;
      right: 0.22rem;
    }
  }

  .title2 {
    width: 0.58rem;
    height: 0.58rem;
    background: #2c698b;
  }

  .title3 {
    width: 0.58rem;
    height: 0.58rem;
    background: #e4a239;
  }

  .title4 {
    width: 0.58rem;
    height: 0.58rem;
    background: #0eaa71;
    border-radius: 0 0 0.2rem 0;
  }

  // 弹出样式
  .title1_main {
    width: 5.13rem;
    height: 3.45rem;
    background: #fff;
    border-radius: 0.2rem;
    box-shadow: 0 0.04rem 0.04rem 0.04rem rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0.17rem;
    left: 0.6rem;
    z-index: -1;

    .top {
      padding-left: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 0.2rem;

      img {
        width: 0.2rem;
        height: 0.2rem;
      }
    }

    .putbox {
      width: 4.37rem;
      height: 0.76rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .put {
        width: 100%;
        height: 0.3rem;
        line-height: 0.3rem;
        border-radius: 0.06rem;
        border: 0.01rem solid #d9d9d9;
        text-decoration: none;
        text-indent: 0.1rem;
        box-sizing: border-box;
        margin: 0 0 0.05rem 0;

        &::placeholder {
          color: #e7e7e7;
        }
      }
    }

    .msgbox {
      width: 4.37rem;
      height: 0.96rem;
      margin: 0 auto;

      .msgput {
        padding-left: 0.1rem;
        width: 4.3rem;
        height: 0.96rem;
        border-radius: 0.06rem;
        border: 0.01rem solid #d9d9d9;

        &::placeholder {
          color: #e7e7e7;
        }
      }
    }

    .subbtn {
      width: 4.37rem;
      height: 0.4rem;
      background-color: #005e3c;
      text-align: center;
      margin: 0.3rem auto 0;
      line-height: 0.4rem;
      font-size: 0.22rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      border-radius: 0.06rem;
    }
  }

  .title_main {
    width: 1.41rem;
    height: 0.58rem;
    text-align: center;
    color: #fff;
    font-family: Rubik One-Regular;
    font-weight: 900;
    font-size: 0.18rem;
    line-height: 0.58rem;

    a {
      text-decoration: none;
      color: #fff;
    }
  }

  .t1 {
    background: #e4a239;
    font-weight: bold;
    position: absolute;
    font-size: 0.24rem;
    font-family: Passion One, Passion One;
    z-index: -1;
    top: 1.91rem;
    left: 0.58rem;
    border-radius: 0 0.2rem 0.2rem 0;
  }

  .t2 {
    background: #0eaa71;
    font-weight: bold;
    position: absolute;
    font-size: 0.24rem;
    font-family: Passion One, Passion One;
    z-index: -1;
    top: 2.49rem;
    left: 0.58rem;
    border-radius: 0 0.2rem 0.2rem 0;
  }

  .t3 {
    background: #0eaa71;
    position: absolute;
    font-size: 0.24rem;
    font-family: Passion One, Passion One;
    font-weight: bold;
    z-index: -1;
    top: 3.07rem;
    left: 0.58rem;
    border-radius: 0 0.2rem 0.2rem 0;
  }

  .t5 {
    border-radius: 0;
  }
}
.mb-sidebarbox {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 1999;

  .mb-title1 {
    width: 1.85rem;
    height: 1.5rem;
    background: #72ba50;
    font-size: 0.7rem;
  }
  .mb-show-item {
    width: 17.3rem;
    display: flex;
    align-items: center;
    position: relative;
    .itembox {
      width: 33%;
      height: 1.5rem;
      line-height: 1.5rem;
    }
    .i1 {
      background: #269ad2;
    }
    .i2 {
      background: #3773b8;
    }
    .i3 {
      background: #000000;
    }
    .mb_title1 {
      width: 19.2rem;
      height: 16.5rem;
      position: absolute;
      bottom: 1.5rem;
      background: #fff;
      font-size: 0.6rem;
      font-family: Rubik One-Regular;
      font-weight: 900;
      left: -1.9rem;
      border-radius: 0.6rem;
      box-sizing: border-box;
      padding: 0 1rem;
      box-shadow: 0 -0.4rem 0.8rem rgba(0, 0, 0, 0.3);
      .mb_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .mb_putbox {
        width: 100%;
        .put {
          width: 100%;
          height: 1.33rem;
          margin-bottom: 0.4rem;
          border: 0.02rem solid #d9d9d9;
          text-emphasis: none;
          padding-left: 0.3rem;
          box-sizing: border-box;
        }
      }
      .mb_msgbox {
        width: 100%;
        .msgput {
          width: 100%;
          padding-left: 0.3rem;
          box-sizing: border-box;
          border: 0.02rem solid #d9d9d9;
        }
      }
      .mb_subbtn {
        width: 100%;
        height: 1.6rem;
        background: #000000;
        color: #fff;
        text-align: center;
        line-height: 1.6rem;
        border-radius: 0.1rem;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>