<template>
  <div class="comp-c-select">
    <div class="select-box point" :style="`height:${height}rem;width:${width}rem;`" @click="isShowFloat = !isShowFloat">
      <div class="input-value flex-s" :class="{'mb-input-value':isMinDevice}">
        {{ parseSelectList.find((item) => item.value == value)?.label }}
      </div>
      <div class="row" :class="{'mb-row':isMinDevice}" :style="`top:${height / 2 - 0.034*(isMinDevice?5:1)}rem;`"></div>
      <div v-show="isShowFloat" :style="`top:${height}rem;`" class="float-box">
        <div @click="changeSelect(item)" v-for="(item, index) in parseSelectList" :key="index" :style="`width:${width}rem;`" class="float-box-item flex-s" :class="{'mb-float-box-item':isMinDevice}">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    selectList: {
      type: Array,
      default() {
        return [];
      },
    },
    height: {
      type: [Number, String],
      default: 0.4,
    },
    width: {
      type: [Number, String],
      default: 2.2,
    },
  },
  data() {
    return {
      isShowFloat: false,
    };
  },
  methods: {
    changeSelect(item) {
      this.$emit("input", item.value);
    },
  },
  computed: {
    ...mapState(["isMinDevice"]),
    parseSelectList() {
      const array = this.selectList.map((item) => {
        return {
          label: item?.label || item,
          value: item?.value || item,
        };
      });
      return array;
    },
  },
};
</script>
 
<style lang="scss">
.comp-c-select {
  .select-box {
    position: relative;
    // background: #eee;
    border: 1px solid rgba(156, 156, 156, 1);
    box-sizing: border-box;
    padding-right: 0.4rem;

    .input-value {
      width: 100%;
      height: 100%;
      padding-left: 0.2rem;
      box-sizing: border-box;
      font-size: 0.18rem;
      color: #666;
      overflow: hidden;
      white-space: nowrap;
    }
    .mb-input-value {
      font-size: 0.6144rem;
    }

    .row {
      width: 0;
      height: 0;
      border: 0.1rem solid;
      border-color: rgba(174, 174, 174, 1) transparent transparent transparent;
      position: absolute;
      top: 0rem;
      right: 0.11rem;
      z-index: 1;
      transform: translateY(-0.03rem);
    }
    .mb-row {
      border: 0.4rem solid;
      border-color: rgba(174, 174, 174, 1) transparent transparent transparent;
    }

    .float-box {
      position: absolute;
      left: -1px;
      background: rgba(255, 255, 255, 0.7);
      z-index: 99;

      .float-box-item {
        padding: 0.12rem 0.3rem;
        box-sizing: border-box;
        font-size: 0.18rem;
        color: #666;
        white-space: nowrap;
        overflow: hidden;
      }
      .mb-float-box-item {
        font-size: 0.512rem;
        padding: 0.6rem 0.6rem;
        background: rgba(0, 56, 121, 0.6);
        color: #ffffff;
      }

      .float-box-item:hover {
        background: rgba(0, 56, 121, 0.6);
        color: #ffffff;
      }
    }
  }
}
</style>