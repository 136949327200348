import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations.js";
import actions from "./actions.js";

Vue.use(Vuex);

const state = {
  userInfo: undefined,
  // onlineUrl: "http://192.168.0.21:81",
  onlineUrl: "https://api.orectofloor.com",
};

const getters = {
  isLogin(state) {
    return !!state.userInfo?.token;
  },
};
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});
