<template>
  <div id="app">
    <router-view />
    <sidebar/>
    <div style="opacity: 0; width: 0; height: 0" id="footer"></div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import sidebar from "../src/views/Sidebar/index.vue";
export default {
  name: "",
  components: {
     sidebar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(["ReFreshUserInfo"]),
    ...mapMutations(["setDeviceType", "setToken"]),
    init() {
      console.log("是否登录", this.isLogin);
      this.getDeviceType();
      const token = localStorage.getItem("token");
      if (token) {
        this.setToken(token);
      }
      if (this.isLogin) {
        this.ReFreshUserInfo();
      }
      addEventListener("resize", () => {
        this.getDeviceType();
      });
    },
    // 获取设备分辨率
    getDeviceType() {
      let width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      console.log('设备宽度', width);
      if (width < 750) {
        this.setDeviceType(true);
      } else {
        this.setDeviceType(false);
      }
    },
  },
};
</script>

<style lang="scss">
// @import "./styles/bootstrap.css";
@import "./styles/common.scss";
@import "./styles/mixin.scss";
// @import "./assets/fonts/FZZ.css";
</style>
