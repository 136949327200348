import Vue from "vue";
import VueRouter from "vue-router";
// import { Message } from "element-ui";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法，解决vue路由重复导航错误
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "WrapIndex",
    component: () => import("@/views/WrapIndex/index.vue"),
    redirect: "/Home",
    children: [
      {
        path: "/Home",
        name: "Home",
        component: () => import("@/views/Home/index.vue"),
      },
      {
        path: "/Faq",
        name: "Fqa",
        component: () => import("@/views/Faq/index.vue"),
      },
      {
        path: "/Service",
        name: "Service",
        component: () => import("@/views/Service/index.vue"),
      },
      {
        path: "/Product",
        name: "Product",
        component: () => import("@/views/Product/index.vue"),
      },
      {
        path: "/About",
        name: "About",
        component: () => import("@/views/About/index.vue"),
      },
      {
        path: "/goodsDetaile",
        name: "goodsDetaile",
        component: () => import("@/views/Product/goodsDetaile.vue"),
      }, 
      {
        path: "/ContactUs",
        name: "ContactUs",
        component: () => import("@/views/ContactUs/index.vue"),
      },
      {
        path: "/Case",
        name: "Case",
        component: () => import("@/views/Case/index.vue"),
      },
      {
        path: "/News",
        name: "News",
        component: () => import("@/views/New/index.vue"),
      },
      {
        path: "/NewsDetaile",
        name: "NewsDetaile",
        component: () => import("@/views/New/newDetaile.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
